var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"bg-light"},[_c('div',{staticClass:"un-section-seller container"},[_vm._m(0),_c('section',{staticClass:"categories-tag-search solid-bg-white"},[_c('div',{staticClass:"content-tags other-style px-0"},[_c('ul',{staticClass:"nav nav-pills nav-tab-search no-wrap pl-4 justify-content-start w-100",staticStyle:{"overflow-x":"scroll","display":"flex","flex-direction":"row","flex-wrap":"nowrap !important"},attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"btn btn-tag-filter d-block",attrs:{"role":"presentation"}},[_c('span',{class:['px-3', { active: _vm.selected === 'All' }],attrs:{"id":"pills-all-tab","data-bs-toggle":"pill","data-bs-target":"#pills-all","role":"tab","aria-controls":"pills-all","aria-selected":"true"},on:{"click":function($event){_vm.selected = 'All'}}},[_vm._v(" All ")])]),_vm._l((_vm.Wordpress),function(category,index){return _c('li',{key:index,staticClass:"btn btn-tag-filter d-block",attrs:{"role":"presentation"}},[_c('span',{class:[
                  'px-3 text-nowrap',
                  { active: _vm.selected === category.name },
                ],attrs:{"id":'pills-' + category.name + '-tab',"data-bs-toggle":"pill","data-bs-target":'#pills-' + category.name,"type":"button","role":"tab","aria-controls":'pills-' + category.name,"aria-selected":"true"},domProps:{"innerHTML":_vm._s(category.name)},on:{"click":function($event){_vm.selected = category.name;
                  _vm.changeSlug(category.id);}}})])})],2)])]),_c('section',{staticClass:"discover-NFTs-gradual pt-3 px-0",staticStyle:{"padding-top":"16px","padding-bottom":"16px"}},[_c('div',{staticClass:"tab-content",attrs:{"id":"pills-tabContent"}},[_c('div',{class:[
              'tab-pane fade',
              { show: _vm.selected === 'All' },
              { active: _vm.selected === 'All' },
            ],attrs:{"id":"#pills-all","role":"tabpanel","aria-labelledby":"pills-all-tab"}},[_c('div',{staticClass:"row"},_vm._l((_vm.articleList),function(article,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-3 col-sm-12 col-xs-12 mt-3"},[_c('div',{staticClass:"item-card-gradual"},[_c('router-link',{staticClass:"body-card",attrs:{"to":'/article-detail/' + article.slug}},[_c('div',{staticClass:"cover-nft"},[_c('picture',[(article._embedded['wp:featuredmedia'])?_c('source',{attrs:{"srcset":article._embedded['wp:featuredmedia'][0]
                              .source_url,"type":"image/webp"},on:{"error":() =>
                              (article._embedded[
                                'wp:featuredmedia'
                              ][0].source_url =
                                '/images/other/news-empty.png')}}):_vm._e(),(article._embedded['wp:featuredmedia'])?_c('img',{staticClass:"image-article",attrs:{"src":article._embedded['wp:featuredmedia'][0]
                              .source_url,"alt":"image article"},on:{"error":() =>
                              (article._embedded[
                                'wp:featuredmedia'
                              ][0].source_url =
                                '/images/other/news-empty.png')}}):_vm._e()]),_c('div',{staticClass:"icon-type"},[_c('i',{staticClass:"ri-vidicon-line"})])]),_c('div',{staticClass:"d-flex mt-3 gap-2"},[_c('div',{staticClass:"badge bg-success"},[_vm._v(" "+_vm._s(article?._embedded?.["wp:term"][0][0]?.name)+" ")])]),_c('div',{staticClass:"title-card-nft"},[_c('div',{staticClass:"side-one"},[_c('h2',{domProps:{"innerHTML":_vm._s(article.title?.rendered)}}),_c('p',[_vm._v(" "+_vm._s(_vm.moment(article.date_gmt).format("MMMM Do YYYY"))+" ")])]),_c('div',{staticClass:"side-other"},[_c('span',{staticClass:"no-sales"},[_vm._v(_vm._s(article.est_time_read))])])]),_c('div',{staticClass:"creator-name"},[_c('div',{staticClass:"image-user"},[_c('picture',[_c('source',{attrs:{"srcset":"images/avatar/14.webp","type":"image/webp"}}),_c('img',{staticClass:"img-avatar",attrs:{"src":"images/avatar/14.jpg","alt":""}})]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"ri-checkbox-circle-fill"})])]),_c('h3',[_vm._v("Dino Dapps")])])])],1)])}),0)]),_vm._l((_vm.Wordpress),function(category,index){return _c('div',{key:index,class:[
              'tab-pane fade',
              { show: _vm.selected === category.name },
              { active: _vm.selected === category.name },
            ],attrs:{"id":'#pills-' + category.name,"role":"tabpanel","aria-labelledby":'pills-' + category.name + '-tab'}},[_c('div',{staticClass:"row"},_vm._l((_vm.listArticle),function(article,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-3 col-sm-12 col-xs-12 mt-3"},[_c('div',{staticClass:"item-card-gradual"},[_c('router-link',{staticClass:"body-card",attrs:{"to":'/article-detail/' + article.slug}},[_c('div',{staticClass:"cover-nft"},[_c('picture',[(article._embedded['wp:featuredmedia'])?_c('source',{attrs:{"srcset":article._embedded['wp:featuredmedia'][0]
                              .source_url,"type":"image/webp"},on:{"error":() =>
                              (article._embedded[
                                'wp:featuredmedia'
                              ][0].source_url =
                                '/images/other/news-empty.png')}}):_vm._e(),(article._embedded['wp:featuredmedia'])?_c('img',{staticClass:"image-article",attrs:{"src":article._embedded['wp:featuredmedia'][0]
                              .source_url,"alt":"image article"},on:{"error":() =>
                              (article._embedded[
                                'wp:featuredmedia'
                              ][0].source_url =
                                '/images/other/news-empty.png')}}):_vm._e()]),_c('div',{staticClass:"icon-type"},[_c('i',{staticClass:"ri-vidicon-line"})])]),(_vm.selected != null)?_c('div',{staticClass:"d-flex mt-3 gap-2"},[_c('div',{staticClass:"badge bg-success",domProps:{"innerHTML":_vm._s(_vm.selected)}})]):_c('div',{staticClass:"d-flex mt-3 gap-2"},[_c('div',{staticClass:"badge bg-success"},[_vm._v("All Category")])]),_c('div',{staticClass:"title-card-nft"},[_c('div',{staticClass:"side-one"},[_c('h2',{domProps:{"innerHTML":_vm._s(article.title?.rendered)}}),_c('p',[_vm._v(" "+_vm._s(_vm.moment(article.date_gmt).format("MMMM Do YYYY"))+" ")])]),_c('div',{staticClass:"side-other"},[_c('span',{staticClass:"no-sales"},[_vm._v(_vm._s(article.est_time_read))])])]),_c('div',{staticClass:"creator-name"},[_c('div',{staticClass:"image-user"},[_c('picture',[_c('source',{attrs:{"srcset":"images/avatar/14.webp","type":"image/webp"}}),_c('img',{staticClass:"img-avatar",attrs:{"src":"images/avatar/14.jpg","alt":""}})]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"ri-checkbox-circle-fill"})])]),_c('h3',[_vm._v("Dino Dapps")])])])],1)])}),0)])})],2)])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-4 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"mt-4 mt-3"},[_c('h2',{staticClass:"fw-bold title-gradient"},[_vm._v("Article List")]),_c('p',[_vm._v("All Information About Crypto")])])])
}]

export { render, staticRenderFns }