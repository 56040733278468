<template>
  <div>
    <Header />

    <div class="bg-light">
      <div class="un-section-seller container">
        <div class="pt-4 d-flex justify-content-between align-items-center">
          <div class="mt-4 mt-3">
            <h2 class="fw-bold title-gradient">Article List</h2>
            <p>All Information About Crypto</p>
          </div>
        </div>
        <section class="categories-tag-search solid-bg-white">
          <div class="content-tags other-style px-0">
            <ul
              class="nav nav-pills nav-tab-search no-wrap pl-4 justify-content-start w-100"
              style="
                overflow-x: scroll;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap !important;
              "
              id="pills-tab"
              role="tablist"
            >
              <li class="btn btn-tag-filter d-block" role="presentation">
                <span
                  @click="selected = 'All'"
                  :class="['px-3', { active: selected === 'All' }]"
                  id="pills-all-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-all"
                  role="tab"
                  aria-controls="pills-all"
                  aria-selected="true"
                >
                  All
                </span>
              </li>
              <li
                class="btn btn-tag-filter d-block"
                role="presentation"
                v-for="(category, index) in Wordpress"
                :key="index"
              >
                <span
                  @click="
                    selected = category.name;
                    changeSlug(category.id);
                  "
                  :class="[
                    'px-3 text-nowrap',
                    { active: selected === category.name },
                  ]"
                  :id="'pills-' + category.name + '-tab'"
                  data-bs-toggle="pill"
                  :data-bs-target="'#pills-' + category.name"
                  type="button"
                  role="tab"
                  :aria-controls="'pills-' + category.name"
                  aria-selected="true"
                  v-html="category.name"
                ></span>
              </li>
            </ul>
          </div>
        </section>
        <section
          class="discover-NFTs-gradual pt-3 px-0"
          style="padding-top: 16px; padding-bottom: 16px"
        >
          <div class="tab-content" id="pills-tabContent">
            <div
              :class="[
                'tab-pane fade',
                { show: selected === 'All' },
                { active: selected === 'All' },
              ]"
              id="#pills-all"
              role="tabpanel"
              aria-labelledby="pills-all-tab"
            >
              <div class="row">
                <div
                  class="col-lg-4 col-md-3 col-sm-12 col-xs-12 mt-3"
                  v-for="(article, index) in articleList"
                  :key="index"
                >
                  <div class="item-card-gradual">
                    <router-link
                      :to="'/article-detail/' + article.slug"
                      class="body-card"
                    >
                      <div class="cover-nft">
                        <picture>
                          <source
                            v-if="article._embedded['wp:featuredmedia']"
                            :srcset="
                              article._embedded['wp:featuredmedia'][0]
                                .source_url
                            "
                            @error="
                              () =>
                                (article._embedded[
                                  'wp:featuredmedia'
                                ][0].source_url =
                                  '/images/other/news-empty.png')
                            "
                            type="image/webp"
                          />
                          <img
                            class="image-article"
                            v-if="article._embedded['wp:featuredmedia']"
                            :src="
                              article._embedded['wp:featuredmedia'][0]
                                .source_url
                            "
                            @error="
                              () =>
                                (article._embedded[
                                  'wp:featuredmedia'
                                ][0].source_url =
                                  '/images/other/news-empty.png')
                            "
                            alt="image article"
                          />
                        </picture>
                        <div class="icon-type">
                          <i class="ri-vidicon-line"></i>
                        </div>
                      </div>
                      <div class="d-flex mt-3 gap-2">
                        <div class="badge bg-success">
                          {{ article?._embedded?.["wp:term"][0][0]?.name }}
                        </div>
                      </div>
                      <div class="title-card-nft">
                        <div class="side-one">
                          <h2 v-html="article.title?.rendered"></h2>
                          <p>
                            {{
                              moment(article.date_gmt).format("MMMM Do YYYY")
                            }}
                          </p>
                        </div>
                        <div class="side-other">
                          <span class="no-sales">{{
                            article.est_time_read
                          }}</span>
                        </div>
                      </div>
                      <div class="creator-name">
                        <div class="image-user">
                          <picture>
                            <source
                              srcset="images/avatar/14.webp"
                              type="image/webp"
                            />
                            <img
                              class="img-avatar"
                              src="images/avatar/14.jpg"
                              alt=""
                            />
                          </picture>
                          <div class="icon">
                            <i class="ri-checkbox-circle-fill"></i>
                          </div>
                        </div>
                        <h3>Dino Dapps</h3>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(category, index) in Wordpress"
              :key="index"
              :class="[
                'tab-pane fade',
                { show: selected === category.name },
                { active: selected === category.name },
              ]"
              :id="'#pills-' + category.name"
              role="tabpanel"
              :aria-labelledby="'pills-' + category.name + '-tab'"
            >
              <div class="row">
                <div
                  class="col-lg-4 col-md-3 col-sm-12 col-xs-12 mt-3"
                  v-for="(article, index) in listArticle"
                  :key="index"
                >
                  <div class="item-card-gradual">
                    <router-link
                      :to="'/article-detail/' + article.slug"
                      class="body-card"
                    >
                      <div class="cover-nft">
                        <picture>
                          <source
                            v-if="article._embedded['wp:featuredmedia']"
                            :srcset="
                              article._embedded['wp:featuredmedia'][0]
                                .source_url
                            "
                            @error="
                              () =>
                                (article._embedded[
                                  'wp:featuredmedia'
                                ][0].source_url =
                                  '/images/other/news-empty.png')
                            "
                            type="image/webp"
                          />
                          <img
                            class="image-article"
                            v-if="article._embedded['wp:featuredmedia']"
                            :src="
                              article._embedded['wp:featuredmedia'][0]
                                .source_url
                            "
                            @error="
                              () =>
                                (article._embedded[
                                  'wp:featuredmedia'
                                ][0].source_url =
                                  '/images/other/news-empty.png')
                            "
                            alt="image article"
                          />
                        </picture>
                        <div class="icon-type">
                          <i class="ri-vidicon-line"></i>
                        </div>
                      </div>
                      <div class="d-flex mt-3 gap-2" v-if="selected != null">
                        <div class="badge bg-success" v-html="selected"></div>
                      </div>
                      <div class="d-flex mt-3 gap-2" v-else>
                        <div class="badge bg-success">All Category</div>
                      </div>
                      <div class="title-card-nft">
                        <div class="side-one">
                          <h2 v-html="article.title?.rendered"></h2>
                          <p>
                            {{
                              moment(article.date_gmt).format("MMMM Do YYYY")
                            }}
                          </p>
                        </div>
                        <div class="side-other">
                          <span class="no-sales">{{
                            article.est_time_read
                          }}</span>
                        </div>
                      </div>
                      <div class="creator-name">
                        <div class="image-user">
                          <picture>
                            <source
                              srcset="images/avatar/14.webp"
                              type="image/webp"
                            />
                            <img
                              class="img-avatar"
                              src="images/avatar/14.jpg"
                              alt=""
                            />
                          </picture>
                          <div class="icon">
                            <i class="ri-checkbox-circle-fill"></i>
                          </div>
                        </div>
                        <h3>Dino Dapps</h3>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "Blog",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      articleList: [],
      Wordpress: [],
      moment: moment,
      listArticle: [],
      selected: "All",
      slug: 269,
      queryOptions: {
        per_page: 12, // Only retrieve the 10 most recent blog posts.
        page: 1, // Current page of the collection.
        _embed: true, //Response should include embedded resources.
      },
    };
  },
  mounted() {
    this.getArticle();
    this.getWordpress();
  },
  created() {
    this.getArticles(this.page);
    this.getArticle();

    this.$mixpanel.track("Visit Blog Page");
  },
  methods: {
    async getArticle() {
      await axios
        .get(
          "https://api.webooswap.com/wordpress/?rest_route=/wp/v2/posts?_embed=wp:term",
          {
            params: this.queryOptions,
          }
        )
        .then((response) => (this.articleList = response.data));
    },

    async getWordpress() {
      axios
        .get(
          "https://api.webooswap.com/wordpress/?rest_route=/wp/v2/categories/"
        )
        .then((res) => {
          this.Wordpress = res.data;
        });
    },

    changeSlug(slug) {
      this.slug = slug;
      this.getArticles();
    },

    getArticles(page = 1) {
      axios
        .get(
          "https://api.webooswap.com/wordpress/?rest_route=/wp/v2/posts?categories=" +
            this.slug +
            "&page=" +
            page,
          {
            params: this.queryOptions,
          }
        )
        .then((response) => {
          this.listArticle = response.data;
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((e) => {
          console.log("Uncaught Error" + e);
        });
    },
  },
};
</script>

<style scoped>
.image-article {
  max-width: 100%;
  width: 100%;
  height: 220px;
  border-radius: 12px;
}
</style>
